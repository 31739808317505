import {
    makeStyles
  } from "@mui/styles";
  
  export const betCardStyles = makeStyles((theme) => ({

    bottomButtons:{ 
      display:'flex',
      justifyContent:'space-between',
      alignSelf:'center',
    }
    
  }));
  